import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import Landing from '../templates/Landing';

const events = {
  header: {
    title: 'eventsHeaderTitle',
    subTitle: 'eventsHeaderSubTitle',
    image: 'ControlEventsWite.png',
    // button1: {
    //   text: 'registration',
    //   subText: 'registrationSubText',
    //   link: host,
    //   event: 'Header / Get Started'
    // },
    button2: {
      text: 'orderDemo',
      link: '/form/live-demo',
      event: 'EV_equest_demo',
      id: 'request_demo_events'
    }
  },
  content: [
    {
      title: 'eventsContentCheckTitle',
      subTitle: 'eventsContentCheckSubTitle',
      image: 'check.png',
    },
    {
      title: 'eventsContentDashTitle',
      subTitle: 'eventsContentDashSubTitle',
      image: 'dnk.png',
    },
    {
      title: 'eventsContentWorkTitle',
      subTitle: 'eventsContentWorkSubTitle',
      image: 'peoplePC.png',
    },
    {
      title: 'eventsContentImportTitle',
      subTitle: 'eventsContentImportSubTitle',
      image: 'box.png',
    },
    {
      title: 'eventsContentControlTitle',
      subTitle: 'eventsContentControlSubTitle',
      image: 'gajets.png',
    },
  ],
  demo: {
    event: 'EV_digital_core_start'
  }
};

const Events = (props) => (
  <Layout {...props}>
    <Landing
      data={events}
    />
  </Layout>
);


export default withIntl(Events);

